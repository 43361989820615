var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("headbox", {
        attrs: {
          title: "关于我们",
          "bg-color": "#39394d",
          isbackPath: true,
          path: "person"
        }
      }),
      !_vm.loading
        ? _c("div", { staticClass: "profile" }, [
            _c("h2", { staticClass: "title" }, [
              _vm._v("深圳市" + _vm._s(_vm.GLOBAL) + "回收")
            ]),
            _c("p", { staticClass: "content" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.GLOBAL) +
                  "，坐落于中国黄金珠宝交易的心脏地带——深圳水贝，是一家专注于贵金属回购业务的企业。我们以“专业、安全、便捷”为宗旨，致力于为广大消费者提供一个可信赖的黄金回购平台。作为贵金属回收行业的佼佼者，" +
                  _vm._s(_vm.GLOBAL) +
                  "拥有专业的检测、技术和运营团队。确保每件黄金都经过精确的检测，对黄金的纯度、重量和成色进行严格把关，从而给予客户公正、合理的回购价格。同时，通过不断的努力改进，形成具有" +
                  _vm._s(_vm.GLOBAL) +
                  "特色的报价系统和客户定价、现结、存料延后结算的服务模式。针对客户采用一站式服务。优化交易中的繁琐细节，确保客户在整个回购过程中获得最佳体验。 "
              )
            ]),
            _c("img", {
              attrs: {
                src: require("@/assets/images/about_yellow.png"),
                alt: ""
              }
            }),
            _c("p", { staticClass: "content" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.GLOBAL) +
                  "将继续深耕贵金属回购领域，努力成为行业的领军企业。我们将不断提升服务质量和效率，为客户提供更加便捷、透明的回购体验。同时，我们将积极拓展市场份额，将" +
                  _vm._s(_vm.GLOBAL) +
                  "的品牌影响力推向更广阔的舞台。在整个黄金市场，" +
                  _vm._s(_vm.GLOBAL) +
                  "将秉持诚信经营、创新发展的理念，与各方携手共进，共同推动黄金产业的繁荣与发展。我们相信，在未来的日子里，" +
                  _vm._s(_vm.GLOBAL) +
                  "将成为连接消费者与黄金市场的桥梁和纽带，为更多人带来财富的增值和美好的生活。 "
              )
            ])
          ])
        : _vm._e(),
      _vm.loading
        ? _c(
            "center",
            { attrs: { id: "loadingbox" } },
            [_c("van-loading", { attrs: { size: "70px" } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }