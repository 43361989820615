<template>
  <!-- 协议列表 -->
  <div>
    <headbox
           title="关于我们"
           bg-color="#39394d"
          :isbackPath="true"
          path="person"></headbox>
<!--      <img  :src="img" alt="" v-if="!loading">-->
    <div class="profile" v-if="!loading">
      <h2 class="title">深圳市{{GLOBAL}}回收</h2>
      <p class="content">
        {{GLOBAL}}，坐落于中国黄金珠宝交易的心脏地带——深圳水贝，是一家专注于贵金属回购业务的企业。我们以“专业、安全、便捷”为宗旨，致力于为广大消费者提供一个可信赖的黄金回购平台。作为贵金属回收行业的佼佼者，{{GLOBAL}}拥有专业的检测、技术和运营团队。确保每件黄金都经过精确的检测，对黄金的纯度、重量和成色进行严格把关，从而给予客户公正、合理的回购价格。同时，通过不断的努力改进，形成具有{{GLOBAL}}特色的报价系统和客户定价、现结、存料延后结算的服务模式。针对客户采用一站式服务。优化交易中的繁琐细节，确保客户在整个回购过程中获得最佳体验。
      </p>
      <img src="@/assets/images/about_yellow.png" alt="">
        <p class="content">
          {{GLOBAL}}将继续深耕贵金属回购领域，努力成为行业的领军企业。我们将不断提升服务质量和效率，为客户提供更加便捷、透明的回购体验。同时，我们将积极拓展市场份额，将{{GLOBAL}}的品牌影响力推向更广阔的舞台。在整个黄金市场，{{GLOBAL}}将秉持诚信经营、创新发展的理念，与各方携手共进，共同推动黄金产业的繁荣与发展。我们相信，在未来的日子里，{{GLOBAL}}将成为连接消费者与黄金市场的桥梁和纽带，为更多人带来财富的增值和美好的生活。
        </p>
    </div>
      <center id="loadingbox" v-if="loading">
        <van-loading  size="70px" />
      </center>
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
import * as commonpro from "@/utils/api";
export default {
  data() {
    return {
          loading:false,
          img:''
    };
  },
 async mounted(){
   this.loading = false
   // let res = await commonpro.getAboutPhoto()
   // res.forEach(item => {
   //   if (item.eventType == "ABOUT") {
   //        this.img = item.img
   //        this.loading = false
   //   }
   // });
  },
  components: {
    headbox
  }
};
</script>
<style lang="scss" scoped="true">
#loadingbox {
  margin-top: 150px;
}
::v-deep .van-collapse-item__title:after {
  content: none !important;
}
::v-deep .van-collapse-item__content {
  padding: 0;
}
::v-deep .van-collapse {
  margin: 0 30px;
}
::v-deep .van-icon {
  font-size: 36px;
  margin-top: 35px;
  transform: translate(-50%);
}
::v-deep .van-cell {
  border-bottom: 1px solid #cccccc;
  padding: 0;
}
::v-deep .van-cell__title {
  color: #292929;
  font-size: 32px;
  // margin: 0 30px;
  padding: 35px 0;
  padding-left: 20px;
}
.maxcenter {
  line-height: 48px;
  // margin: 0 30px;
  padding: 30px 0;
  font-size: 26px;
  border-bottom: 1px solid #cccccc;
}
.silderarrow {
  transform: rotate(180deg);
}
.list {
}
.cell > div {
  //  width: 90%;
  //  text-align: right;
  margin-left: 0.3rem;
  //  text-align: center;
  //   vertical-align: middle;
}
img {
  width: 100%;
}
.title {
  padding-top: 40px;
  color: #242242;
  font-size: 36px;
  text-align: center;
}

.content {
  color: #6f6f6f;
  text-align: left;
  padding: 35px 50px 0 50px;
  font-size: 30px;
  line-height: 1.6;
  font-weight: 500;
}
</style>
